import React from 'react';

import Page from '../components/Page';
import Content from '../components/Content';
import SEO from '../components/SEO';
import Image from '../components/Image';

function Post({ pageContext }) {
  const {
    title,
    metaDescription,
    featuredImage: { sizes: { src: featuredImage } = {} } = {},
    content: { json } = {}
  } = pageContext;
  const body = (
    <>
      <SEO
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        image={featuredImage}
      />

      <Image src={featuredImage} />

      <Content json={json} />
    </>
  );

  return <Page title={title} body={body} />;
}

export default Post;
